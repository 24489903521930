<script setup></script>
<template>
  <v-footer theme="dark" absolute>
    <v-container>
      <v-row>
        <v-col cols="12" md="4" class="d-flex flex-column">
          <div>
            <img src="/logo_dark.svg" height="65" style="max-width: 100%" />
          </div>
          <v-spacer />
          <div class="text-subtitle-2 text-medium-emphasis">
            @ 2024 Бүх эрх хуулиар хамгаалагдсан.
          </div>
        </v-col>
        <v-col cols="12" md="3">
          <div class="text-primary font-weight-medium" style="font-size: 15px">Холбоосууд</div>
          <NuxtLink to="/faq" class="footer-list-item"> Түгээмэл асуулт хариулт </NuxtLink>
          <NuxtLink to="/terms" class="footer-list-item"> Үйлчилгээний нөхцөл </NuxtLink>
          <NuxtLink to="/privacy-policy" class="footer-list-item"> Нууцлалын бодлого </NuxtLink>
        </v-col>
        <v-col cols="12" md="3">
          <div class="text-primary font-weight-medium" style="font-size: 15px">Холбоо барих</div>
          <NuxtLink href="tel:+61433035464" class="footer-list-item">
            <v-icon icon="icon-phone" size="x-small" color="#737A8C" class="mr-2" />
            +61 433 035 464
          </NuxtLink>
          <NuxtLink href="mailto:toono.mngl@gmail.com" class="footer-list-item">
            <v-icon icon="icon-mail" size="x-small" color="#737A8C" class="mr-2" />
            toono.mngl@gmail.com
          </NuxtLink>
          <!-- <NuxtLink to="/banner-ad" class="footer-list-item"> Баннер байршуулах </NuxtLink> -->
        </v-col>
        <v-col cols="12" md="2">
          <div class="text-primary font-weight-medium" style="font-size: 15px">Сошиал хаяг</div>
          <v-btn
            href="https://www.facebook.com/profile.php?id=61563495092214"
            target="_blank"
            color="white"
            variant="tonal"
            class="mt-3"
          >
            <img class="mr-1" src="/brands/facebook_sign_in.svg" width="20" height="20" />
            Toono
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>
<style scoped>
.footer-list-item {
  display: block;
  text-decoration: none;
  color: white;
  margin: 18px 0;
  font-size: 15px;
}

.footer-list-item:hover {
  text-decoration: underline;
}
</style>
